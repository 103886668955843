import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'

const About = () => (
  <div id="apie" name="apie" className="about">
    <div className="holder">
      <Grid fluid>
        <Row>
          <Col lg={4} xs={12}>
            <section>
              <h1>Naudojamos technologijos</h1>
              <ul>
                  <li><span>Ruby on Rails</span></li>
                  <li><span>Angular, React, React-Native</span></li>
                  <li><span>PostgreSQL, Redis</span></li>
                  <li><span>Amazon Web Services (AWS)</span></li>
              </ul>
            </section>
            </Col>
            <Col lg={2} xs={12}>
                <quote>
                12+
                <abbr title="10 veiklos metų">Veiklos metų</abbr>
                </quote>
            </Col>
            <Col lg={2} xs={12}>
                <quote>
                10
                <abbr title="12 aukšto lygio specialistų">Aukšto lygio specialistų</abbr>
                </quote>
            </Col>
            <Col lg={2} xs={12}>
                <quote>
                300+
                <abbr title="320+ klientų">Klientų</abbr>
                </quote>
            </Col>
            <Col lg={2} xs={12}>
                <quote>
                9
                <abbr title="9 šalys">Šalys</abbr>
                </quote>
            </Col>
        </Row>
      </Grid>
    </div>
  </div>
)

export default About