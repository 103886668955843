import React from 'react'
import Link from 'gatsby-link'
// import { Grid, Row, Col } from 'react-flexbox-grid'

import Services from '../components/Services'
import Process from '../components/Process'
import About from '../components/About'
import Customers from '../components/Customers'
import Contacts from '../components/Contacts'
import Header from '../components/Header'

const IndexPage = () => (
  <div>
    <Header content={<div><h1>Verslo valdymo ir savitarnos sistemų programavimas</h1><hr /><h2>Padedame įmonėms atrasti ir išnaudoti pilną savo potencialą</h2></div>} /> 
    <Services />
    <Process />
    <About />
    <Customers />
    <Contacts />
  </div>
)

export default IndexPage
