import React from 'react'
import { css } from 'glamor'
import { Row, Col } from 'react-flexbox-grid'

import Slider from 'react-slick'

function importAll(r) {
return r.keys().map(r);
}


  
const CustomLogos = importAll(require.context('./', false, /\.(png|jpe?g|svg)$/)).map((image) =>
    
    <div><a css={{
        height: 150,
        display: 'block',
        width: 200,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: `url(${image.default})`
    }}>&nbsp;</a></div>
);
let SliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false
}

if (typeof window !== 'undefined') {
    SliderSettings.slidesToShow = ((window.innerWidth < 768) ? 2 : 5 );
}



const Customers = () => (
  <div className="holder">
    <div  id="klientai" name="klientai" className="customers">
        <h2>Klientai</h2>
        <section>
        <Slider {...SliderSettings}>
            {CustomLogos}
        </Slider>
        </section>
    </div>
    </div>
)

export default Customers

