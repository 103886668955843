import React from 'react'
import { css } from 'glamor'
import { Row, Col } from 'react-flexbox-grid'
import PoreikiuAnalize from './poreikiu-analize.png'
import Samata from './samata.png'
import ProjektoSpecifikacija from './projekto-specifikacija.png'
import UXDizainas from './ui-ux-dizainas.png'
import Programavimas from './programavimas.png'
import Testavimas from './testavimas.png'
import TolimesnisVystymas from './tolimesnis-vystymas.png'


const ProcessItem = (props) => (
  <section  className="process" css={{background: `white url(${props.image}) no-repeat center 40px`}}>
    <h3>{props.title}</h3>
  </section>
)

const Process = () => (
  <div className="holder">
    <div id="paslaugu-eiga" name="paslaugu-eiga" className="processes">
      <h2>Paslaugų eiga</h2>
      <ProcessItem title="Poreikių analizė" image={PoreikiuAnalize} />
      <ProcessItem title="Sąmata" image={Samata} />
      <ProcessItem title="Projekto specifikacija" image={ProjektoSpecifikacija} />
      <ProcessItem title="UI/UX dizainas" image={UXDizainas} />
      <ProcessItem title="Programavimas" image={Programavimas} />
      <ProcessItem title="Testavimas" image={Testavimas} />
      <ProcessItem title="Tolimesnis vystymas" image={TolimesnisVystymas} />
    </div>
  </div>
  )
  
  export default Process
  